
.portfolioFilter {
    span {
        transition: all 0.4s ease-in-out;
        line-height: 30px;
        font-weight: 500;
        &:hover, &.active {
            color: $primary !important;
            cursor: pointer;
        }
    }
}

.portfolio-box {
    .portfolio-box-img {
        img {
            transition: all 0.5s ease;
        }
        .overlay-work {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            opacity: 0; 
            transition: all 0.5s ease;
            background-color: rgba($white, 0.7);
            .work-content {
                position: absolute;
                bottom: 50%;
                transform: translateY(50%);
                right: 0;
                left: 0;
                .work-icon {
                    height: 45px;
                    width: 45px;
                    line-height: 45px;
                    transform: translateY(40px);
                    transition: all 0.5s ease;
                    &:hover {
                        background: $primary !important;
                    }
                }
                .work-style-three {
                    transform: translateY(-100%);
                    transition: all 0.5s ease;
                    .title {
                        transition: all 0.5s ease;
                        &:hover {
                            color: $primary !important;
                        }
                    }
                }
            }
        }
    }
    .gallary-title {
        .title {
            font-size: 18px;
            transition: all 0.5s ease;
            &:hover {
                color: $primary !important;
            }
        }   
    }
    &:hover {
        .portfolio-box-img{
            img {
                transform: rotate(2deg) scale(1.15);
                opacity: 0.5;
            }
            .overlay-work {
                opacity: 1;
                transform: scale(1);
                .work-content {
                    .work-icon, .work-style-three { 
                        transform: translateY(0px);
                    }
                }
            }
        }
    }
}

.spacing{
    padding-left: 5px;
    padding-right: 5px;
}