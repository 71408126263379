.feature {
    transition: all 0.5s ease;
    .content {
        .title {
            font-size: 18px;
            font-weight: 500;
        }
        a {
            transition: all 0.5s ease;
            &:hover {
                color: $primary !important;
            }
        }
    }
}