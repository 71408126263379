.container {
    @media (min-width: 1400px) {
        max-width: 1140px !important;
    }
}

.row > * {
    position: relative;
}

@each $heading,
$size in $font-sizes {
    #{$heading} {
        font-size: $size;
    }
}

@each $heading,
$size in $display-font-sizes {
    .#{$heading} {
        font-size: $size !important;
    }
}

@each $name,
$value in $colors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }
    .text-#{$name} {
        color: #{$value} !important;
    }
    a {
        &.text-#{$name} {
            &:hover {
                color: darken($value, 10%) !important;
            }
        }
    }
}

.list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 0px;
    line-height: 0;
} 


.rounded {
    border-radius: 5px !important;
}
.rounded-top {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}
.rounded-left {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}
.rounded-bottom {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.rounded-right {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}