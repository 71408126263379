.team-content {
    transition: all 0.5s ease;
    .detail {
        .name {
            transition: all 0.5s ease;
            &:hover {
                color: $primary !important;
            }
        }
        .posting {
            font-size: 13px;
        }
    }
    .team-social {
        position: absolute;
        top: 10px;
        left: 10px;
        opacity: 0;
        transition: all 0.5s ease;
    }
    &:hover {            
        box-shadow: $shadow;
        .team-social {
            opacity: 1;
        }
    }
}