.component-wrapper {
    .title {
        font-size: 24px;
    }
}

@each $name,
$value in $colors {
    .btn-#{$name} {
        background-color: #{$value} !important;
        border: 1px solid #{$value} !important;
        color: $white !important;
        box-shadow: 0 3px 7px rgba($value, 0.5);
        &:hover, &:focus, &:active, &.active, &.focus{
            background-color: darken($value, 10%) !important;
            border-color: darken($value, 10%) !important;
            color: $white !important;
            transform: translateY(-2px);
        }
    }

    .btn-outline-#{$name} {
        border: 1px solid #{$value};
        color: #{$value};
        background-color: transparent;    
        &:hover, &:focus, &:active, &.active, &.focus, &:not(:disabled):not(.disabled):active{
            background-color: #{$value};
            border-color: #{$value};
            color: $white;
            box-shadow: 0 3px 7px rgba($value, 0.5);
            transform: translateY(-2px);
        }
    }
}
.btn {
    padding: 8px 20px;
    outline: none;
    text-decoration: none;
    font-size: 15px;
    letter-spacing: 0.5px;
    transition: all 0.3s;
    font-weight: 500;
    border-radius: 5px;
    &:focus{
        box-shadow: none !important;
    }
    &.btn-sm{
        padding: 7px 16px;
        font-size: 10px;
    }
    &.btn-lg{
        padding: 14px 30px;
        font-size: 16px;
    } 
    &.searchbtn {
        padding: 6px 20px;
    }

    &.btn-light {
        color: $dark !important;
        border: 1px solid $gray-300 !important;
        &:hover, &:focus, &:active, &.active, &.focus{
            background-color: darken($light, 10%) !important;
            color: $dark !important; 
        }
    } 
     
    &.btn-outline-light {
        border: 1px solid $gray-300 !important;
        color: $dark !important;
        background-color: transparent;    
        &:hover, &:focus, &:active, &.active, &.focus{
            background-color: $light !important;
        }
    }
}

button:not(:disabled) {
    outline: none;
}


.shadow {
    box-shadow: $shadow !important;
}


.border {
    border: 1px solid $gray-200 !important;
}
.border-top {
    border-top: 1px solid $gray-200 !important;    
}
.border-bottom {
    border-bottom: 1px solid $gray-200 !important;    
}
.border-left {
    border-left: 1px solid $gray-200 !important;    
}
.border-right {
    border-right: 1px solid $gray-200 !important;    
}


.page-next-level{
    .title {
        font-size: 28px;
        letter-spacing: 1px;
    }
    .page-next {
        letter-spacing: 0.5px;
        li {
            margin-right: 5px;
            &:after {
                content: "\f0142";
                font-size: 16px;
                color: $dark; 
                font-family: 'Material Design Icons';
            }     
            &:last-child {
                margin-right: 0;
                &:after {
                    display: none;
                }
            }       
            a, span {        
                font-size: 14px;
                padding-right: 8px;           
                transition: all 0.5s ease;
                font-size: 13px;
                font-weight: 500;
            }
            a {
                &:hover {
                    color: $primary !important;
                }
            }
            span {
                &:last-child {
                    padding-right: 0px;
                }
            }
        }
    }
}


.avatar {
    &.avatar-ex-sm{
        height: 30px;
    }
    &.avatar-md-sm{
        height: 50px;
    }
    &.avatar-small{
        height: 70px;
    }
    &.avatar-medium{
        height: 110px;
    }
    &.avatar-large{
        height: 140px;
    }
}


.faq-container {
    .question {
        font-size: 20px;
    }
}

.faq-content {
    .card {
        border-radius: 12px !important;
        .faq {           
            .card-header {
                padding-right: 40px !important;
                &.card-header-2 {
                    border-bottom: 0;
                }
            }
        }  
        a.faq[data-toggle=collapse]{
            background-color: $white;
            color: $primary;
            transition: all 0.5s;
            &:before {
                content: '\F0143';
                display: block;
                font-family: 'Material Design Icons';
                font-size: 18px;
                color: $dark;
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                transition: all 0.5s ease;
            }
            &.collapsed {
                background-color: $white;
                color: $dark;
                transition: all 0.5s;
                &:before {
                    top: 15px;
                    transform: rotate(180deg);
                }
            }
        }
        .faq-question {
            font-size: 16px;
        }
    }
}


.pagination {
    font-size: 14px;
    li {
        &:first-child a {
            border-left-width: 1px !important;
            border-radius: 5px 0 0 7px;
        } 
        &:last-child a{
            border-radius: 0 7px 7px 0;
        }
        a {
            float: left;
            color: $dark;
            border: 1px solid $gray-200;
            border-left-width: 0 !important;
            background: $white; 
            &:hover {
                color: $primary;
                background: $gray-200;
                border-color: $gray-200;
            }
        }
    }              
    .active a {
        color: $white !important;
        background: $primary !important;
        border-color: $primary !important;
        cursor: not-allowed;
    }
}


.progress-box {
    .title {
        font-size: 15px !important;
    }
    .progress {
        height: 9px;
        overflow: visible;
        background: darken($light, 3%);
        .progress-bar {
            height: 4px;
            border-radius: 5px;
            margin: auto 5px;
            animation: animate-positive 3s;
            overflow: visible !important;
        }
        .progress-value {
            position: absolute;
            top: -25px;
            right: -15px;
            font-size: 13px;
        }
    }
    @keyframes animate-positive {
        0% {
            width: 0;
        }
    }
}


@each $name,
$value in $colors {
    .badge-#{$name} {
        background-color: #{$value} !important;
        color: $white !important;
    }

    .badge-outline-#{$name} {
        background-color: transparent !important;
        color: #{$value} !important;
        border: 1px solid #{$value} !important;
    }
}
.badge {
    padding: 5px 8px;
    border-radius: 3px;
    letter-spacing: 0.5px;
    font-size: 12px;
    &.badge-light {
        color: $dark !important;
        background-color: $light !important;
    }
    &.badge-outline-light {
        color: $dark !important;
        border: 1px solid darken($light, 20%) !important;
        background-color: transparent !important;
    }
    &:focus, &.focus {
        box-shadow: none !important;
    }
    &.badge-pill {
        border-radius: 10px;
    }
}


.form-label {
    font-size: 14px;
    font-weight: 500;
}
.form-control {
    box-shadow: none;
    border: 2px solid $gray-200;
    height: 42px; 
    font-size: 13px;
    border-radius: 5px;
    transition: all 0.5s ease;
    &:focus, &.active {
        border-color: $primary;
        box-shadow: none;
    }
} 
textarea {
    height: 120px !important;
    &.form-control {
        line-height: 25px;
    }
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(45deg, $white, $white);
    z-index: 9999999;
    #status {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        .sk-chase {
            width: 40px;
            height: 40px;
            position: relative;
            animation: sk-chase 2.5s infinite linear both;
            margin: 0 auto;
            .sk-chase-dot {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0; 
                animation: sk-chase-dot 2.0s infinite ease-in-out both; 
                &:before {
                    content: '';
                    display: block;
                    width: 25%;
                    height: 25%;
                    background-color: $primary;
                    border-radius: 100%;
                    animation: sk-chase-dot-before 2.0s infinite ease-in-out both; 
                }
                &:nth-child(1), 
                &:nth-child(1):before { 
                    animation-delay: -1.1s; 
                }
                &:nth-child(2), 
                &:nth-child(2):before { 
                    animation-delay: -1.0s; 
                }
                &:nth-child(3), 
                &:nth-child(3):before { 
                    animation-delay: -0.9s; 
                }
                &:nth-child(4), 
                &:nth-child(4):before { 
                    animation-delay: -0.8s;
                }
                &:nth-child(5), 
                &:nth-child(5):before { 
                    animation-delay: -0.7s; 
                }
                &:nth-child(6), 
                &:nth-child(6):before { 
                    animation-delay: -0.6s; 
                }                
            }
        }
    }
}

@keyframes sk-chase {
    100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot {
    80%, 100% { transform: rotate(360deg); } 
}

@keyframes sk-chase-dot-before {
    50% {
        transform: scale(0.4); 
    } 100%, 0% {
        transform: scale(1.0); 
    } 
}


.social-icon {
    li {
        a {
            color: $dark;
            border: 1px solid $dark;
            display: inline-block;
            height: 32px;
            text-align: center;
            font-size: 15px;
            width: 32px;
            line-height: 30px;
            transition: all 0.4s ease;
            overflow: hidden;
            position: relative;
            border-radius: 5px;
            &:hover {
                background-color: $primary;
                border-color: $primary !important;
                color: $white !important;
            }
        }
    }
    &.social {
        li {
            a {
                color: $gray-500;
                border-color: $gray-500;
            }
        }
    }
}


.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99; 
    width: 35px;
    height: 35px;
    line-height: 35px;
    background-color: $gray-100;
    transition: all 0.5s ease; 
    i {
        font-size: 16px;   
    }
    &:hover, &:focus {
        background-color: $primary;
        i {
            color: $white !important;
        }
    }
}

.home-shape-arrow {
    position: absolute;
    left: 0;
    bottom: 25px;
    z-index: 1;
    width: 100%;
    height: auto;
    .arrow-icon {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        transition: all 0.5s ease;
        height: 50px;
        width: 50px;
        margin: 0 auto;
        line-height: 50px;
    }
    &:hover {
        .arrow-icon {
            color: $primary !important;
        }
    }
}


.watch-video {
    a {
        &.watch {
            font-size: 14px;
            font-weight: 700;
    
        }
        .play-icon-circle {
            height: 36px;
            width: 36px;
            line-height: 36px;
            &.play-iconbar {
                font-size: 16px;
            }
            &.play {
                top: 77%;
            }
            &.home-play {
                height: 60px;
                width: 60px;
                line-height: 60px;
            }
            &.home-play-2 {
                box-shadow: 0 0 45px rgba($primary, 0.9) !important;
            }
        }
    }

    &.play-icon {
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
        right: 0;
        left: 0;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .display-1 {
        font-size: 56px !important;
    }
    .display-2 {
        font-size: 50px !important;
    }
    .display-3 {
        font-size: 42px !important;
    }
    .display-4 {
        font-size: 36px !important;
    }
}